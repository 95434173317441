<template>
  <v-app class="d-flex">
    <template v-if="isTopBarNeeded" >
      <top-bar class="app--header" />
      <navigation-drawer class="app--header__mobile" />
    </template>
    <v-main id="main">
      <div class="main--content">
        <router-view/>
      </div>
    </v-main>
    <error-dialog/>
  </v-app>
</template>

<script>

import TopBar from "@/components/TopBar/Bar";
import NavigationDrawer from "@/components/TopBar/NavigationDrawer";
import ErrorDialog from "@/components/Error";

export default {
  name: 'App',
  components: {ErrorDialog, TopBar, NavigationDrawer},
  computed: {
    isTopBarNeeded: {
      get () {
        return this.$store.state.user.id !== -1
      }
    },
  },
  data: () => ({
    //
  }),
  created() {
    this.$store.dispatch('user/getUser')
    this.$router.afterEach(to => {
      if (to.name === 'Dashboard') {
        this.$store.dispatch('user/getUserSums')
      }
    })
  }
};
</script>

<style>
.app--header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>

<style scoped>
#main {
  max-width: 800px;
  align-self: center;
  margin-top: 64px;
  width: 100%;
}
.main--content {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.app--header__mobile {
  display: none;
}
</style>

<style>
h1 {
  text-align: center;
}
.display-flex {
  display: flex;
}
.w-100 {
  width: 100%;
}
@media only screen and (max-width: 1180px) {
  .app--header {
    display: none !important;
  }

  .app--header__mobile {
    display: block !important;
  }

  #main {
    margin-top: 56px !important;
  }
  .dashboard--element {
    margin: 20px 0;
  }
  .main--content {
    align-items: center;
  }
}
</style>