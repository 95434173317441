import Level from "@/types/level";
import {diaryColumnTypes} from "@/common";

export default class Record {
    /**
     * @type Number
     */
    id;
    /**
     * @type Number
     */
    refCategoryId;
    /**
     * @type Category
     */
    refCategory;
    /**
     * @type Number|Array<Number>
     */
    uid;
    /**
     * User
     * @type string
     */
    userName;
    /**
     * @type Number
     */
    levelId;
    /**
     * @type Level
     */
    level
    /**
     * @type Object
     */
    data;
    /**
     * @type Array<Number>
     */
    files;
    /**
     * @type Number
     */
    sum;

    constructor(rec, refCat) {
        this.id = rec.id;
        this.refCategoryId = typeof rec.refCategory === "number" ? rec.refCategory : rec.refCategoryId;
        this.refCategory = refCat
        this.uid = rec.uid;
        this.levelId = rec.level;
        this.level = refCat.levels.find(l => l.id === rec.level);
        if (this.level == null) {
            console.warn("record level is null, level id:", rec.level);
        } else {
            this.level = new Level(this.level)
        }
        this.data = rec.data;
        this.files = rec.files || [];
        this.sum = rec.sum;
    }

    toJson() {
        return {
            id: this.id,
            refCategory: this.refCategoryId,
            uid: this.uid,
            level: this.levelId,
            data: this.data,
            files: this.files,
            sum: this.sum
        }
    }

    copy(deep = false) {
        // TODO: deep copy
        if (deep) {
            console.log('todo');
        }
        return new Record(this.toJson(), this.refCategory);
    }

    calcSum() {
        if (this.data == null || this.data.length === 0) {
            console.warn("Trying to calculate sum of record without data")
            return;
        }
        if (this.level == null) {
            console.warn("Trying to calculate sum of record without level");
            return;
        }
        if (this.refCategory == null) {
            console.warn("Trying to calculate sum of record without refCategory resolved")
            return;
        }

        this.sum = 0;

        for (const colId in this.data) {
            if (!Object.hasOwn(this.data, colId)) continue;
            let col = this.refCategory.columns.find(col => col.id === Number(colId));
            if (!col) {
                console.warn("Column with id", colId, "not found");
                continue;
            }
            if (!(col.type === diaryColumnTypes.value || col.forceAsValue)) {
                continue;
            }

            let data = this.data[colId];

            if (col.type > diaryColumnTypes.latest) {
                data.forEach(valId => {
                    this.sum += this.level.customPrice ? this.level.customPrice[valId] : 0;
                });
                return;
            }

            data.forEach(valRaw => {
                let val = parseFloat(valRaw);
                if (val > this.level.reference) {
                    this.sum += this.level.price?.better;
                } else if (val == this.level.reference) {
                    this.sum += this.level.price?.equal;
                } else {
                    this.sum += this.level.price?.lower;
                }
            });
            return;
        }

        let val = this.data[Object.keys(this.data)[0]].length;
        if (val > this.level.reference) {
            this.sum += this.level.price?.better;
        } else if (val == this.level.reference) {
            this.sum += this.level.price?.equal;
        } else {
            this.sum += this.level.price?.lower;
        }
    }
}