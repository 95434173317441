// noinspection JSUnfilteredForInLoop

import axios from '../../../plugins/axios'
import {apiEndpoint, portfolioRecordType, shallowCopy} from "@/common";

export default {
    namespaced: true,
    state: () => ({
        isRecordsUpdating: false,
        records: {},
        scoring: [],
        students: {},
        studentsRaw: []
    }),
    mutations: {
        setScoring(state, scoring) {
            state.scoring = scoring;
        },
        // Set or Update Records
        setRecords(state, {records, uRole}) {
            if (records.length === 0) {
                state.records = null;
                return;
            }

            let nRecords = shallowCopy(state.records);

            switch (uRole) {
                case "Director":
                case "classMaster":
                    for (let rIdx in records) {
                        let record = records[rIdx];
                        if (!record.user) {
                            console.error("No user provided by server (portfolio/setRecords).");
                            return;
                        }
                        let cl = record.user.classes[0];
                        if (!nRecords[cl.id]) nRecords[cl.id] = {...cl, students: {}, sum: 0};

                        let students = nRecords[cl.id].students;

                        if (!students[record.uid]) {
                            students[record.uid] = {
                                ...record.user,
                                types: {
                                    [portfolioRecordType.act]: {records: [], sum: 0},
                                    [portfolioRecordType.student]: {records: [], sum: 0},
                                    [portfolioRecordType.sportsmen]: {records: [], sum: 0},
                                    [portfolioRecordType.talent]: {records: [], sum: 0}
                                },
                                sum: 0
                            };
                        }
                        let type = students[record.uid].types[record.type];
                        let rid = type.records.indexOf(record.id)
                        if (rid === -1) {
                            type.records.push(record);
                        } else {
                            type.records[rid] = record;
                        }
                        if (!record.scorePrice) {
                            console.error("Record #" + record.id + " doesn't have a scorePrice. Skipping... Record content: ", record);
                            continue;
                        }
                        type.sum += record.scorePrice;
                        students[record.uid].sum += record.scorePrice;
                        nRecords[cl.id].sum += record.scorePrice;
                    }
                    break;
                case "student":
                    nRecords.sum = 0;
                    for (let rIdx in records) {
                        let record = records[rIdx];
                        if (!nRecords[record.type]) nRecords[record.type] = {records: [], sum: 0};

                        let rid = nRecords[record.type].records.indexOf(record.id)
                        if (rid === -1) {
                            nRecords[record.type].records.push(record);
                        } else {
                            nRecords[record.type].records[rid] = record;
                        }

                        if (!record.scorePrice) {
                            console.error("Record #" + record.id + " doesn't have a scorePrice. Skipping... Record content: ", record);
                            continue;
                        }

                        nRecords[record.type].sum += record.scorePrice;
                        nRecords.sum += record.scorePrice;
                    }
                    break;
            }

            state.records = nRecords;
        },
        setStudents(state, students) {
            state.studentsRaw = students;
            let nStudents = {};

            for (let stIdx in students) {
                let student = students[stIdx];
                let cid = student.classes[0].id;
                if (!nStudents[cid]) nStudents[cid] = {id: cid, name: student.classes[0].name, students: []}
                nStudents[cid].students.push(student);
            }

            state.students = nStudents;
        }
    },
    actions: {
        async getRecords(ctx) {
            if (ctx.state.isRecordUpdating) return;
            console.log('dispatching getRecords...')
            ctx.state.isRecordUpdating = true;
            let res = await axios.get(apiEndpoint.portfolio.records)
            if (res.status !== 200) {
                ctx.commit('showError', res.data, {root: true});
                ctx.state.isRecordUpdating = false;
                return
            }
            ctx.commit('setRecords', {records: res.data, uRole: ctx.rootState.user.role});
            ctx.state.isRecordUpdating = false;
        },
        async putRecords(ctx, records) {
            console.log('dispatching putRecords...')
            let res = await axios.put(apiEndpoint.portfolio.records, records)
            if (res.status !== 200) {
                ctx.commit('showError', res.data, {root: true});
                return false;
            }
            ctx.commit('setRecords', {records: res.data, uRole: ctx.rootState.user.role});
            return true
        },
        async getScoring(ctx){
            console.log('dispatching getScoring...')
            let res = await axios.get(apiEndpoint.portfolio.scoring)
            if (res.status !== 200) {
                ctx.commit('showError', res.data, {root: true});
                return false
            }
            ctx.commit('setScoring', res.data);
            return true
        },
        async getStudents(ctx) {
            console.log('dispatching getStudents...')
            let res = await axios.get(apiEndpoint.portfolio.students)
            if (res.status !== 200) {
                ctx.commit('showError', res.data, {root: true});
                return false;
            }
            ctx.commit('setStudents', res.data);
            return true;
        }
    }
}