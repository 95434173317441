import axios from "@/plugins/axios";
import {apiEndpoint} from "@/common";
import Level from "@/types/level";
import Category from "@/types/category";

const CATEGORIES_PAGINATION_LIMIT = 20;

const fixCategory = (c) => {
    if (c.columns.length > 0 && c.columns[0].id === undefined) c.columns[0].id = 0;
    if (c.levels.length > 0 && c.levels[0].id === undefined) c.levels[0].id = 0;
    c.levels = c.levels.map(l => new Level(l));
}

export default {
    namespaced: true,
    state: {
        categories: [],
        lastIds: {
            top: 0
        },
        cache: {
            complete: {
                root: false
            }
        },
    },
    mutations: {
        addCategories(state, {cats, parent}) {
            cats.forEach(c => {
                fixCategory(c);
                this.commit('crm/categories/cacheCategory', c)
            });
            // Arrays reactivity issue
            state.categories = state.categories.concat(cats);
            if (parent === 0) {
                state.lastIds.top = cats[cats.length-1].id;
            } else {
                state.lastIds[parent] = cats[cats.length-1].id;
            }
        },
        cacheCategory(state, c) {
            state.cache[c.id] = c;
        },
        setCacheCompleteState(state, {parent, isComplete}) {
            if (!parent) {
                state.cache.complete.root = isComplete
                return;
            }
            state.cache.complete[parent] = isComplete;
        }
    },
    actions: {
        async getNextTopCategories(ctx, {limit}) {
            if (ctx.state.cache.complete.root) return true;

            let cats = await axios.get(apiEndpoint.categories, {
                params: {
                    // In case we get all categories we should set limit to negative
                    // bc js negation will return true if limit will be 0,
                    // but server requires 0 value if we need to remove limit
                    // Note: do not use no-limit requests without reason
                    limit: !limit ? CATEGORIES_PAGINATION_LIMIT : (limit < 0 ? 0 : limit),
                    start: ctx.state.lastIds.top+1,
                    parent: 0
                }
            });
            if (cats.status !== 200) {
                ctx.commit('showError', cats.data, {root: true});
                return false;
            }
            if (cats.data.length === 0) return false;
            ctx.commit('addCategories', {cats: cats.data.map(c => new Category(c)), parent: 0});
            if (limit < 0 || cats.data.length < (!limit ? CATEGORIES_PAGINATION_LIMIT : limit)) {
                ctx.commit('setCacheCompleteState', {isComplete: true});
            }
            return true;
        },

        async getNextCategories(ctx, {parent, limit}) {
            if (ctx.state.cache.complete[parent]) return true;

            let cats = await axios.get(apiEndpoint.categories, {
                params: {
                    limit: limit ? (limit < 0 ? 0 : limit) : CATEGORIES_PAGINATION_LIMIT,
                    start: !ctx.state.lastIds[parent] ? 0 : ctx.state.lastIds[parent]+1,
                    parent: parent
                }
            });
            if (cats.status !== 200) {
                ctx.commit('showError', cats.data, {root: true});
                return false;
            }

            if (cats.data.length === 0) return false;

            let pCat = null;
            if (parent > 0) pCat = await ctx.dispatch('crm/categories/getCategoryById', parent, {root: true});

            let categories = cats.data.map(c => new Category(c, pCat));
            categories.forEach(fixCategory);

            ctx.commit('addCategories', {
                cats: categories,
                parent
            });

            if (limit < 0 || cats.data.length < (!limit ? CATEGORIES_PAGINATION_LIMIT : limit)) {
                ctx.commit('setCacheCompleteState', {isComplete: true, parent});
            }
            return true;
        },

        async getCategoryById(ctx, id) {
            let cat = ctx.state.cache[id];
            if (cat != null) return cat;

            cat = await axios.get(apiEndpoint.categories, {
                params: {id}
            });
            if (cat.status !== 200) {
                ctx.commit('showError', cat.data, {root: true});
                return null
            }
            let parent = null;
            if (cat.data[0].parent) {
                 parent = await ctx.dispatch('crm/categories/getCategoryById', cat.data[0].parent, {root: true});
            }
            let category = new Category(cat.data[0], parent);
            fixCategory(category);
            ctx.commit('cacheCategory', category);
            return category;
        }
    }
}