import Vue from 'vue'
export const mainDomain = 'onlinemodule.ru';
export const servicesDomain = `service.${mainDomain}`;
export const baseDomain = 'https://portfolio.onlinemodule.ru' // localhost
export const apiUrl = 'https://portfolio.onlinemodule.ru/api/v1' // localhost
export const apiEndpoint = {
  user: "/api/v1/user",
  findUsers: "/api/v1/users/find",
  getUser: "/api/v1/users/get",
  logout: "/api/v1/logout",
  categories: "/api/v1/categories",
  records: "/api/v1/records",
  recordsBulk: "/api/v1/records/bulk",
  sum: "/api/v1/sum",
  summary: '/api/v1/summary',
  files: '/api/v1/files'
};
export const EventBus = new Vue()
export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const addOrdinal = (n, p = {one: '', two: 'а', few: 'а', many: 'ов'} ) => `${p[new Intl.PluralRules('ru').select(n)]}`;
export const urlRegex = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;
export const wrapUrl = str => str.split(' ').map(w=>w.replace(urlRegex, '<a href="$&">$&</a>')).join(' ');
export const isOverflown = (element) => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
export const generateString = length => Array(length).fill('').map(() => Math.random().toString(36).charAt(2)).join('');
export const generateBoolean = () => Math.random() >= 0.5;
export const FEED_UPDATE_INTERVAL = 60000;
export const TASKS_UPDATE_INTERVAL = 60000;
export const SOLVES_UPDATE_INTERVAL = 60000;
export const getExt = fileName => fileName.split('.').pop();
export const pluck = (objs, property) => objs.map(obj => obj[property]);
export const unique = arr => [...new Set(arr)];
export const weekAgo = (date = new Date()) => generateDateArr(-7, date);
export const dateToString = (e = new Date()) => e.toISOString().slice(0, 10);
export const dateArrayToString = d => d.map(dateToString);
export const dateConvert = d => `${padDate(d.getDate())}-${padDate(d.getMonth()+1)}-${padDate(d.getFullYear())} ${padDate(d.getHours())}:${padDate(d.getMinutes())}:${padDate(d.getSeconds())}`;
export const padDate = d => `${d}`.padStart(2, '0');
export const compareDates = (f, s) => {
  let a = toDate(f), b = toDate(s);
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}
export const dateStringToISO = d => dateToString(new Date(d));
export const generateDate = (a, b = new Date()) => new Date(b.valueOf() + 1000 * 60 * 60 * 24 * a);
export const yesterday = (date = new Date()) => generateDate(-1, date);
export const tomorrow = (date = new Date()) => generateDate(1, date);
export const generateDateArr = (a,b = new Date()) => {
    let arr = [];
    for(let i = 0; i <= Math.abs(a); ++i) {
        arr[i] = b;
        b = a < 0 ? yesterday(b) : tomorrow(b);
    }
    return arr;
}
export const deepObjectComparing = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
export const shallowCopy = obj => obj instanceof Array ? [...obj] : {...obj};
export const formatDate = (date) => date.split('-').reverse().join('-');
export const getDateMonth = (date = new Date()) => `${padDate(date.getDate())}.${padDate(date.getMonth()+1)}`
export const getDate = (d = new Date()) => d instanceof Date ? Date.parse(`${dateToString(d)} 0:0:0`) : Date.parse(dateToString(toDate(d)));
export const sum = arr => arr.reduce((a, b) => a + b, 0);
export const isArraysEqual = (a = [], b = []) => a.length === b.length && a.every((v, i) => v === b[i]);
export const getIntersection = (a, b) => [...new Set(a)].filter(v => b.includes(v));
export const getDifference = (a, b) => [...new Set(a)].filter(v => !b.includes(v));
export const toDate = (a = new Date()) => (a instanceof Date) ? a : new Date(a.length < 11 ? `${a} 0:0:0` : a);
export const nl2br = str => str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
export const toMiniRFC3339DateStr = (str = "") => str.slice(0, 10)
export const toMiniRFC3339Date = (d = new Date()) => toMiniRFC3339DateStr(d.toISOString())
export const isArrEqual = (a, b) => JSON.stringify(a.sort()) === JSON.stringify(b.sort());
export const sortBy = (arr, k) => arr.sort((a, b) => (a[k] > b[k] ? 1 : a[k] < b[k] ? -1 : 0));
export const goto = (uri = "") => window.location.assign(uri);
export const fillArray = (arr, val, start = 0, end = 0) => {
  if (start <= end) return []
  for(let i = start; i<end; ++i) {
    arr.push(val)
  }
  return arr;
}
export const promiseChainUntilFalse = async (fn) => {
  let res = false;
  do {
    res = await fn();
  } while (res);
}

export const compressImage = (base64Image, output = 'url') => new Promise( resolve => {
  let canvas = document.createElement('canvas');
  let img = document.createElement('img');
  img.onload = () => {
    const maxWidth = 1920;
    const maxHeight = 1080;
    let imgHeight = img.height;
    let imgWidth = img.width;
    if(imgWidth > imgHeight) {
      if (imgWidth > maxWidth) {
        imgHeight = Math.round((imgHeight *= maxWidth / imgWidth))
        imgWidth = maxWidth;
      }
    } else {
      if (imgHeight > maxHeight) {
        imgWidth = Math.round((imgWidth *= maxHeight / imgHeight));
        imgHeight = maxHeight;
      }
    }
    canvas.width = imgWidth;
    canvas.height = imgHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
    switch (output) {
      case "blob":
        canvas.toBlob(resolve, 'image/jpeg', 0.7)
        break
      default:
        resolve(canvas.toDataURL('image/jpeg', 0.7));
        break
    }
  }
  img.src = base64Image;
});

export const diaryColumnTypes = {
  value: 1,
  plain: 2,
  class: 3,
  subject: 4,
  teacher: 5,
  student: 6,
  latest: 100
};
export const diarySubCatFor = [
  {
    text: "Все",
    value: ""
  },
  {
    text: "Начальная школа",
    value: "primary"
  },
  {
    text: "Среднее звено",
    value: "secondary"
  },
  {
    text: "Старшее звено",
    value: "high"
  }
]
// Enum like obj
export const diaryCreateOrUpdateType = {
  diary: 0,
  0: "diary",

  globalCats: 1,
  1: "globalCats",

  subCats: 2,
  2: "subCats",

  column: 3,
  3: 'column',

  row: 4,
  4: 'row'
};

export const portfolioRecordType = {
  act: 0,
  0: 'act',

  student: 1,
  1: 'student',

  sportsmen: 2,
  2: 'sportsman',

  talent: 3,
  3: 'talent'
}

export const portfolioRecordLevel = {
  'class': 0,
  0: 'Класс',

  school: 1,
  1: 'Школа',

  city: 2,
  2: 'Город',

  region: 3,
  3: 'Область',

  country: 4,
  4: 'Россия',

  world: 5,
  5: 'Мир'
};

export const priceMode = {
  strict: 0,
  0: 'strict',

  light: 1,
  1: 'light',

  lax: 2,
  2: 'lax',

  custom: 3,
  3: 'custom'
}

export const priceModesItems = [
  {
    text: "Балл = реф. → 0",
    value: priceMode.strict
  },
  {
    text: "Балл = реф. → половина цены",
    value: priceMode.light
  },
  {
    text: "Балл = реф. → полная цена",
    value: priceMode.lax
  },
  {
    text: "Ручная настройка",
    value: priceMode.custom
  }
]