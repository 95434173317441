import Vue from 'vue'
import Vuex from 'vuex'
import {apiEndpoint, compressImage} from '@/common';
import user from './modules/user.js';
import crm from './modules/CRM/index';
import axios from '../plugins/axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {
      title: '',
      message: '',
      fatal: false,
      show: false
    },
    classes: []
  },
  mutations: {
    showError(state, err) {
      state.isPageLoading = false;
      state.error.title = err.title;
      state.error.fatal = err.fatal;
      state.error.message = err.content;
      state.error.show = true;
    },
    clearError(state) {
      state.error = {
        title: '',
        message: '',
        fatal: false,
        show: false
      }
    }
  },
  actions: {
    async uploadFiles(ctx, files) {
      let formData = new FormData();
      let filesHandler = () => new Promise(resolve => {
        if(files instanceof Array) {
          let imgToCompress = files.filter(f => f.type.includes("image")); // bruhh
          let aFiles = files.filter(f => !f.type.includes("image")) // bruhh
          if (imgToCompress.length === 0) {
            resolve(aFiles);
            return;
          }
          let processedFiles = []
          imgToCompress.forEach(file => {
            let reader = new FileReader();
            reader.onloadend = () => {
              compressImage(reader.result, 'blob').then(fileBlob => {
                let fNameArr = file.name.split('.')
                fNameArr[fNameArr.length-1]='jpg'

                processedFiles.push(new File([fileBlob], fNameArr.join('.'), {
                  lastModified: file.lastModified,
                  type: 'image/jpeg'
                }));
                if (processedFiles.length === imgToCompress.length) {
                  resolve([...processedFiles, ...aFiles])
                }
              });
            }
            reader.onerror = (e) => {
              console.log("err", e);
            };
            reader.readAsDataURL(file)
          });
        } else {
          resolve(files);
        }
      });

      let pFiles = await filesHandler();
      console.log(pFiles)
      if (pFiles.length > 0) {
        pFiles.forEach(file => {
          console.log("File ", file.name, "has size: ", file.size)
          formData.append("files", file)
        });
      }

      let res = await axios.put(apiEndpoint.files, formData, {
        headers: {
          "Content-Type": "multipart/formdata"
        }
      });
      if (res.status !== 200) {
        ctx.commit("showError", res.data)
        return false
      }
      return res.data;
    }
  },
  modules: {
    user,
    crm
  }
})
