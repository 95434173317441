import diary from "./diary";
import portfolio from './portfolio'
import records from './records'
import categories from "@/store/modules/CRM/categories";

export default {
  namespaced: true,
  modules: {
    diary,
    portfolio,
    records,
    categories
  }
}