import axios from "@/plugins/axios"
import {apiEndpoint} from "@/common";
import {mainDomain} from "@/common";

export default {
  namespaced: true,
  state: {
    name: '',
    surname: '',
    displayname: '',
    role: '',
    flags: 0,
    id: -1,
    classes: [],
    subjects: [],
    mClasses: [],
    patronymic: '',
    password: '',
    isAdmin: false,
    isEmulationNeeded: false,
    sumsByCat: {}
  },
  mutations: {
    setUser(state, user) {
      state.id = user.id;
      state.name = user.name;
      state.surname = user.surname;
      state.patronymic = user.patronymic;
      state.role = user.role;
      state.displayname = user.displayName;
      state.classes = user.classes;
      state.mClasses = user.mClasses;
      state.subjects = user.subjects;
      state.flags = user.flags;
      state.isEmulationNeeded = false;
    },
    clearUser(state) {
      state.id = -1;
      state.name = '';
      state.surname = '';
      state.patronymic = '';
      state.password = '';
      state.email = '';
      state.classes = [];
      state.subjects = [];
      state.mClasses = [];
      state.role = '';
      state.flags = 0;
      state.displayname = '';
      state.isAdmin = false;
      state.sumsByCat = {};
    },
    setSum(state, sums) {
      state.sumsByCat = sums;
    }
  },
  actions: {
    async getUser(ctx) {
      let res = await axios.get(apiEndpoint.user);
      if (res.status === 200) {
        ctx.commit('setUser', res.data);
        return true
      } else {
        if (res.status === 401) {
          console.log('Tried to access protected path wo auth.')
          window.location.replace(`https://${mainDomain}/signup`);
          return false
        }
      }
    },
    async logout(ctx) {
      let res = await axios.get(apiEndpoint.logout);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false
      }
      ctx.commit('clearUser');
      return true
    },
    async getUserById(ctx, {uid}) {
      let res = await axios.get(`${apiEndpoint.getUser}?id=${uid}`);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return [];
      }
      return res.data;
    },
    async findUsers(ctx, {flags, query, className}) {
      let res = await axios.get(`${apiEndpoint.findUsers}?flags=${flags}&q=${query}&class=${className}`);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return [];
      }
      return res.data || [];
    },
    async getUserSums(ctx) {
      let res = await axios.get(`${apiEndpoint.sum}`);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('setSum', res.data);
      return true;
    }
  }
  ,
  getters: {
    isLoggedIn(state){
      return state.id !== -1;
    }
  }

}