import axios from 'axios';
import {apiUrl} from "@/common";

const a = axios.create({
    baseUrl: apiUrl,
});

// Let me handle it directly, without catching exceptions
a.defaults.validateStatus = () => true;

export default a;