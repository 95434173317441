<template>
  <div class="user-profile">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="secondary" size="36" v-on="on" v-bind="attrs">
          <v-icon medium color="primary">mdi-account-outline</v-icon>
        </v-avatar>
      </template>
      <v-list>
        <template v-if="isLoading">
          <v-skeleton-loader type="list-item-two-line"/>
        </template>
        <template v-else>
          <template v-for="(classSums, className) in user.sumsByCat">
            <v-list-item :key="`title-${className}`">
              <v-list-item-title>
                {{ className }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-for="sum in classSums" :key="`sum-${className}-${sum.id}`">
              <v-list-item-avatar>{{ sum.sum }}</v-list-item-avatar>
              <v-list-item-content>{{ sum.name }}</v-list-item-content>
            </v-list-item>

            <v-divider :key="`divider-${className}`"/>
          </template>

        </template>
      </v-list>
      <v-list>
        <v-list-item @click="goto(`https://${mainDomain}/account`)">
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            Профиль
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-avatar>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            Выход
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {goto, mainDomain} from "@/common";
export default {
  name: "TopBarMenu",
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      }
    },
    sumsByCat: {
      get() {
        return this.user.sumsByCat;
      }
    }
  },
  data: () => ({
    mainDomain,
    isLoading: true
  }),
  methods: {
    goto,
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.goto(`https://${mainDomain}`);
      });
    }
  },
  mounted() {
    this.isLoading = Object.keys(this.sumsByCat).length === 0;
  },
  watch: {
    sumsByCat: {
      handler(s) {
        this.isLoading = Object.keys(s).length === 0;
      }
    }
  }
}
</script>

<style scoped>
  .user-profile {
    display: flex;
    align-self: center;
  }
</style>