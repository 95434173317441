<template>
  <div>
    <v-toolbar color="primary" collapse absolute>
      <v-btn @click="drawer = true" icon>
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app color="primary" temporary>
      <v-list-item>
        <v-list-item-content>
          <img src="@/assets/crmlogowhmirrored.png" alt="Онлайн.Модуль" class="module--logo"/>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="white--text --title">
            {{ user.displayname }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text --subtitle">
            {{ classes }}
            <v-spacer></v-spacer>
            <v-btn icon class="user--edit" color="secondary" height="22px" width="22px" @click="goto(`https://${mainDomain}/account`)">
              <v-icon small>fa-pencil</v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense>
        <template v-if="isLoading">
          <v-skeleton-loader type="list-item-two-line"/>
        </template>
        <template v-else>
          <template v-for="(classSums, className) in user.sumsByCat">
            <v-list-item dense :key="`title-${className}`">
              <v-list-item-title class="white--text">{{ className }}</v-list-item-title>
            </v-list-item>
            <v-list-item dense v-for="sum in classSums" :key="`sum-${className}-${sum.id}`">
              <v-list-item-avatar class="white--text">{{ sum.sum }}</v-list-item-avatar>
              <v-list-item-content class="white--text">{{ sum.name }}</v-list-item-content>
            </v-list-item>
            <v-divider :key="`divider-${className}`"/>
          </template>
        </template>
      </v-list>

      <v-list dense nav class="white--text">
        <v-list-item @click="$router.push('/')">
          <v-list-item-icon>
            <v-icon color="white">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Главная</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.flags & 0x100 || user.flags & 0x20 || user.role === 'classMaster'" @click="download(apiEndpoint.summary)">
          <v-list-item-icon>
            <v-icon color="white">mdi-folder-download</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Сводная таблица</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goto(`https://${servicesDomain}`)">
          <v-list-item-icon>
            <v-icon color="white">mdi-view-grid</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Сервисы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon color="white">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text v-navigation-link">Выход</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <iframe style="display: none" ref="dIframe"></iframe>
  </div>

</template>

<script>
import { servicesDomain, mainDomain, goto, apiEndpoint } from '@/common'

  export default {
    name: 'NavigationDrawer',
    computed: {
      user: {
        get() {
          return this.$store.state.user;
        }
      },
      sumsByCat: {
        get() {
          return this.user.sumsByCat;
        }
      },
      classes: {
        get(){
          if(!this.user.classes) return '[ ]';
          let classes = '[ ';
          let cl = this.user.classes.map(e=>e.name);
          if(cl.length > 3) {
            classes += cl.slice(0, 3).join(', ');
            classes += '... ]';
          } else if (cl.length === 1) {
            classes = cl.toString()
          } else {
            classes += cl.join(', ');
            classes += ' ]';
          }
          return classes.toUpperCase();
        }
      },
    },
    data: () => ({
      drawer: false,
      isLoading: true,
      apiEndpoint,
      mainDomain,
      servicesDomain
    }),
    methods: {
      goto,
      logout() {
        this.$store.dispatch('user/logout').then(() => this.goto(`https://${mainDomain}`));
      },
      download(url) {
        this.$refs.dIframe.src = url;
      }
    },
    mounted() {
      this.isLoading = Object.keys(this.sumsByCat).length === 0;
    },
    watch: {
      sumsByCat: {
        handler(s) {
          this.isLoading = Object.keys(s).length === 0;
        }
      }
    }
  };
</script>

<style scoped>
  .--subtitle {
    display: flex;
  }
  .--title {
    text-align: justify;
  }
  .v-navigation-link {
    color: #fff;
    font-size: .9rem !important;
  }
  .module--logo {
    width: 120px;
  }
</style>