<template>
  <v-dialog :value="error.show" @change="dialogOnChange" max-width="800">
    <v-card>
      <v-card-title>{{ error.title }}</v-card-title>
      <v-card-text>{{ error.message }}</v-card-text>
      <v-card-actions>
        <div class="error__actions">
          <v-btn raised color="primary" v-if="error.fatal" @click="reloadPage">Перезагрузить страницу</v-btn>
          <v-btn raised color="primary" v-else @click="clearError">ОК</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  computed: {
    error: {
      get() {
        return this.$store.state.error;
      }
    }
  },
  methods: {
    dialogOnChange(val) {
      if (this.error.show && !val) this.clearError();
    },
    clearError() {
      return this.$store.commit('clearError');
    },
    reloadPage() {
      location.reload()
    }
  }
}
</script>

<style scoped>
  .error__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>