import axios from "./../../../plugins/axios";
import {apiEndpoint, diaryCreateOrUpdateType, unique} from "@/common";

export default {
  namespaced: true,
  state: () => ({
    curDiary: {},
    diaries: [],
    reversedDiaries: [],
    // Autocompletion
    students: [],
    studentsWClass: [],
    subjects: [],
    subjectsWClass: [],
    // Loading state booleans
    isDiariesDispatching: false,
    isStudentsDispatching: false,
    isSubjectsDispatching: false,
  }),
  mutations: {
    setCurDiary(state, diary) {
      diary.cats.forEach(cat => cat.sub.map(subCat => {
        if (subCat.priceMode !== undefined) return subCat;
        if (subCat.columns[0].rows === undefined) {
          subCat.priceMode = 0;
          return subCat;
        }
        let modes = subCat.columns[0].rows.map(row => row.priceMode);
        subCat.columns.forEach(col => col.rows.forEach((row, idx) => row.priceMode = modes[idx] === undefined ? 0 : modes[idx]));
        return subCat;
      }));
      state.curDiary = diary;
    },
    setDiaries(state, diaries) {
      state.diaries = diaries.map(diary => diary.cats.map(cat => cat.sub.map(subCat => {
        if (subCat.priceMode !== undefined) return subCat;
        if (subCat.columns[0].rows === undefined) {
          subCat.priceMode = 0;
          return subCat;
        }
        let modes = subCat.columns[0].rows.map(row => row.priceMode);
        subCat.columns.forEach(col => col.rows.forEach((row, idx) => row.priceMode = modes[idx] === undefined ? 0 : modes[idx]));
        return subCat;
      })));
    },
    setReversedDiaries(state, diaries) {
      let reversed = {};
      diaries.forEach(d => {
        d.cats.forEach(gCat => {
          gCat.sub.forEach(subCat => {
            if (!reversed[subCat.name]) reversed[subCat.name] = [];
            reversed[subCat.name].push({
              user: d.user,
              sum: subCat.sum,
              gCat
            });
          });
        });
      });
      state.reversedDiaries = Object.keys(reversed).map(key => ({name: key, users: reversed[key]}))
    },
    setStudents(state, students) {
      state.students = students.map(s => ({
        text: `${s.displayName}, ${s.classes[0].name}`,
        value: s.id
      }));
    },
    setStudentsWClass(state, students) {
      state.studentsWClass = students.map(s => ({
        text: `${s.displayName}, ${s.classes[0].name}`,
        value: {
          'class': s.classes[0].id,
          'name': s.displayName
        }
      }));
    },
    setSubjects(state, subjects) {
      state.subjects = unique(subjects.map(s => s.name));
    },
    setSubjectsWClass(state, subjects) {
      state.subjectsWClass = subjects.map(s => ({
        text: `${s.name}, ${s.class.name}`,
        value: {
          subject: s.name,
          'class': s.class.id
        }
      }));
    },
    toggleDiariesDispatching(state) {
      state.isDiariesDispatching = !state.isDiariesDispatching;
    },
    toggleStudentsDispatching(state) {
      state.isStudentsDispatching = !state.isStudentsDispatching;
    },
    toggleSubjectsDispatching(state) {
      state.isSubjectsDispatching = !state.isSubjectsDispatching;
    },
    createGlobalCat(state, gCat) {
      state.curDiary.cats.splice(state.curDiary.cats.length-2, 0, gCat);
    },
    deleteByPath(state, path) {
      let scope = state.curDiary;
      let paths = path.split('.');

      path.forEach((part, idx) => {
        if (scope instanceof Array) part = Number(part);
        if (paths.length-2 === idx) {
          if (scope[part] instanceof Array) {
            scope[part].splice(Number(paths[paths.length-1]), 1);
          }
          return;
        }
        if (paths.length-1 === idx) {
          delete scope[part];
          return;
        }
        scope = scope[part]
      });
    },

    updateDiary(state, {path, content}) {
      let scope = state.curDiary;
      let insertInto = null;
      let paths = path.split('.');

      // Seek for path
      paths.forEach((part, idx) => {
        if (paths.length-1 === idx) {
          if (scope[part] instanceof Array) {
            insertInto = part;
          }
          return;
        }
        scope = scope[part]
      });

      if (insertInto) {
        scope[insertInto].push(content);
        return
      }
      scope[paths[paths.length-1]] = content;
    }
  },
  actions: {
    async deleteByPath(ctx, path) {
      console.log('dispatching deleteByPath()')
      let res = await axios.delete(`${apiEndpoint.settings}/${path}`);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      return true
    },
    async createGlobalCat(ctx, gCat) {
      console.log('dispatching createGlobalCat', gCat);
      let res = await axios.put(apiEndpoint.settings, {
            path: 'cats',
            content: JSON.stringify(gCat),
            type: diaryCreateOrUpdateType.globalCats
          });
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('updateDiary', {path: 'cats', content: gCat});
      return true;
    },
    async createSubCat(ctx, {subCat, gCatIdx}) {
      console.log('dispatching createSubCat', subCat, gCatIdx);
      let res = await axios.put(apiEndpoint.settings, {
            path: `cats.${gCatIdx}.sub`,
            content: JSON.stringify(subCat),
            type: diaryCreateOrUpdateType.subCats
          });
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('updateDiary', {path: `cats.${gCatIdx}.sub`, content: subCat});
      return true;
    },
    async createColumn(ctx, {column, gCatIdx, subCatIdx}) {
      console.log('dispatching createColumn', column, subCatIdx, gCatIdx);
      let res = await axios.put(apiEndpoint.settings, {
            path: `cats.${gCatIdx}.sub.${subCatIdx}.columns`,
            content: JSON.stringify(column),
            type: diaryCreateOrUpdateType.column
          });
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('updateDiary', {path: `cats.${gCatIdx}.sub.${subCatIdx}.columns`, content: column});
      return true;
    },
    async createRow(ctx, {row, columnIdx, gCatIdx, subCatIdx}) {
      console.log('dispatching createRow', row, columnIdx, subCatIdx, gCatIdx);
      let res = await axios.put(apiEndpoint.settings, {
            path: `cats.${gCatIdx}.sub.${subCatIdx}.columns.${columnIdx}.rows`,
            content: JSON.stringify(row),
            type: diaryCreateOrUpdateType.row
          });
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return false;
      }
      ctx.commit('updateDiary', {path: `cats.${gCatIdx}.sub.${subCatIdx}.columns.${columnIdx}.rows`, content: row});
      return true;
    },
    async patchSettings(ctx, {path, content, type}) {
      console.log('dispatching patchSettings()');
      let res = await axios.patch(apiEndpoint.settings, {path, content, type});
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return
      }
      ctx.commit('updateDiary', {path, content, type});
    },

    async getStudents(ctx) {
      console.log('trying to dispatch getStudents()');
      if (ctx.state.isStudentsDispatching || !!ctx.state.students.length) return;
      console.log('dispatching getStudents()');
      ctx.commit('toggleStudentsDispatching');

      let res = await axios.get(apiEndpoint.autoCompleting.students);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return
      }
      ctx.commit('setStudents', res.data);
    },
    async getSubjects(ctx) {
      console.log('trying to dispatch getSubjects()');
      if (ctx.state.isSubjectsDispatching || !!ctx.state.subjects.length) return;
      console.log('dispatching getSubjects()');
      ctx.commit('toggleSubjectsDispatching');

      let res = await axios.get(apiEndpoint.autoCompleting.subjects);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return;
      }
      ctx.commit('setSubjects', res.data);
      ctx.commit('setSubjectsWClass', res.data);
    },
    async getDiary(ctx) {
      let res = await axios.get(apiEndpoint.diary);
      if (res.status !== 200) {
        ctx.commit('showError', res.data, {root: true});
        return;
      }
      ctx.commit('setCurDiary', res.data);
    },

    async putDiary(ctx) {
      let res = await axios.post(apiEndpoint.diary, ctx.state.curDiary);
      if (res.status !== 200) {
        ctx.commit("showError", res.data, {root: true});
        return;
      }
      ctx.commit('setCurDiary', res.data);
    },

    async getDiaries(ctx) {
      if (ctx.state.isDiariesDispatching) return;
      ctx.commit('toggleDiariesDispatching');

      let res = await axios.get(apiEndpoint.diaries)
      if (res.status !== 200) {
        ctx.commit("showError", res.data, {root: true});
        return
      }
      ctx.commit("setDiaries", res.data)
      ctx.commit("setReversedDiaries", res.data)
    }
  }
}