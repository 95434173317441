export default class Level {
    /**
     * @type Number|undefined
     */
    id;
    /**
     * @type String
     */
    name;
    /**
     * @type Number
     */
    reference;
    /**
     * @type Object
     */
    price;
    /**
     * @type Object
     */
    customPrice;

    constructor(level) {
        this.id = level.id;
        this.name = level.name;
        this.reference = level.reference || null;
        this.price = level.price || null;
        this.customPrice = level.customPrice || null;
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            reference: this.reference,
            price: this.price
        }
    }
}