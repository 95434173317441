import axios from "@/plugins/axios";
import {apiEndpoint} from "@/common";
import Record from "@/types/record";

const RECORDS_PAGINATION_LIMIT = 20;

export default {
    namespaced: true,
    state: () => ({
        currentRecord: null,
        records: [],
        lastId: 0,
        byCat: {
            // category id: {lastId: 0, records: []}
        },
        filter: {
            catId: 0,
            user: 0,
            level: ''
        }
    }),
    mutations: {
        addRecords(state, recs) {
            // Arrays reactivity issue
            state.records = state.records.concat(recs);
            // Always wrong lastId cause of DESC order of records in response
            // So, iterate over all and find max id
            state.lastId = Math.max(...recs.map(r => r.id));
        },
         addRecordsByCat(state, {parent, recs}) {
            if (!state.byCat[parent]) {
                state.byCat[parent] = {
                    lastId: 0,
                    records: []
                };
            }
            state.byCat[parent].records = state.byCat[parent].records.concat(recs);
            state.byCat[parent].lastId = Math.max(...recs.map(r => r.id));
         },
         setCurrentRecord(state, rec) {
            state.currentRecord = rec;
         },
        resetRecords(state) {
            state.records = [];
            state.lastId = 0;
            state.byCat = {};
        }
    },
    actions: {
        async getRecordsByCategory(ctx, parent) {
            let records = await axios.get(apiEndpoint.records, {
                params: {
                    limit: RECORDS_PAGINATION_LIMIT,
                    start: ctx.state.byCat[parent] == null ? 0 : ctx.state.byCat[parent].lastId+1,
                    category: parent
                }
            });
            if (records.status !== 200) {
                ctx.commit('showError', records.data, {root: true});
                return false;
            }
            if (records.data.length === 0) return false;
            let p = await ctx.dispatch('crm/categories/getCategoryById', parent, {root: true});
            ctx.commit('addRecordsByCat', {parent, recs: records.data instanceof Array ? records.data.map(r => new Record(r, p)) : []});
            return true;
        },

        async getNextRecords(ctx) {
            let records = await axios.get(apiEndpoint.records, {
                params: {
                    limit: RECORDS_PAGINATION_LIMIT,
                    start: ctx.state.lastId+1,
                    category: ctx.state.filter.catId ? ctx.state.filter.catId : 0,
                    user: ctx.state.filter.user ? ctx.state.filter.user : 0
                }
            });
            if (records.status !== 200) {
                ctx.commit('showError', records.data, {root: true});
                return false;
            }
            if (records.data.length === 0) {
                return false;
            }
            let recs = [];
            // TODO: rid of...
            for (const record of records.data) {
                let cat = await ctx.dispatch('crm/categories/getCategoryById', record.refCategory, {root: true})
                if (cat == null) return false;
                let rec = new Record(record, cat);
                if (ctx.state.filter.level && ctx.state.filter.level !== rec.level.name) continue;
                recs.push(rec);
            }
            ctx.commit('addRecords', recs);
            return true;
        },

        async putRecords(ctx, record) {
            let r = await axios.put(record.uid instanceof Array ? apiEndpoint.recordsBulk : apiEndpoint.records, record.toJson())
            if (r.status !== 201) {
                ctx.commit('showError', r.data, {root: true});
                return false;
            }
            return r.data;
        },

        async patchRecords(ctx, record) {
            let r = await axios.patch(`${apiEndpoint.records}/${record.id}`, record.toJson());
            if (r.status !== 200) {
                ctx.commit('showError', r.data, {root: true});
                return false;
            }
            return true;
        }
    }
}