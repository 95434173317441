import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/RecordView.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (store.state.user.role !== "Director" && to.path === "/") {
//     next("/diary");
//     return;
//   }
//   next();
// });

export default router
