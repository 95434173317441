export default class Category {
    /**
     * @type Number|null
     */
    id;
    /**
     * @type String
     */
    name;
    /**
     * @type Number|null
     */
    parentId;
    /**
     * @type Category|null
     */
    parent;
    /**
     * @type Number
     */
    studyingLevel;
    /**
     * @type Array<Object>
     */
    columns;
    /**
     * @type Array<Level>
     */
    levels;

    constructor(cat, parent = null) {
        this.name = cat.name;
        this.parentId = cat.parent;
        this.levels = cat.levels;
        this.columns = cat.columns;
        this.id = cat.id;
        this.studyingLevel = cat.studyingLevel;
        this.parent = parent;
    }
}