<template>
  <v-container style="padding: 0" class="width-unset">
    <v-btn text @click="$router.push('/')" color="secondary">
      Главная
    </v-btn>
    <v-btn text @click="goto(`https://${servicesDomain}/`)" color="secondary">
      Сервисы
    </v-btn>
    <template v-if="show">
      <v-btn text color="secondary" @click="download(apiEndpoint.summary)">Сводная таблица</v-btn>
      <iframe style="display: none" ref="dIframe"/>
    </template>

  </v-container>
</template>

<script>
import { servicesDomain, goto, apiEndpoint } from "@/common";

export default {
  name:'links-bar',
  computed: {
    role: {
      get() {
        return this.$store.state.user.role
      }
    },
    show: {
      get() {
        return this.$store.state.user.flags & 0x100 || this.$store.state.user.flags & 0x20 || this.role === 'classMaster';
      }
    }
  },
  data: () => ({
    servicesDomain,
    apiEndpoint
  }),
  methods: {
    goto,
    download(url) {
      this.$refs.dIframe.src = url;
    }
  }
}
</script>
